import React, { useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import axios from 'axios';
import constant from "../contants/constant"
import { MapContainer, TileLayer, GeoJSON, useMapEvents, Polygon, Marker, Popup, Polyline } from 'react-leaflet'
import WKT from 'terraformer-wkt-parser';
import { BsBookmarkFill, BsCalendar3, BsBorderWidth, BsArrowRightCircleFill, BsFillCaretRightFill } from "react-icons/bs";



export default function MapContainerLocal() {


    useEffect(() => {
        getAllData()

    }, [])




    const [provinces, setProvinces] = useState(null)
    const [mainProjects, setMainProjects] = useState([])
    const [subProjects, setSubProjects] = useState([])

    async function getAllData() {
        // SEGMENTATION PROVINCES
        var rdc_border = await axios.get("drc-map.json")
        var geojson = rdc_border.data;
        setProvinces(geojson)
        // GET ALL DATA
        var all_projects = await axios.get(`${constant.entryReact}project/get-all-projects/`)
        if (all_projects.status == 200) {
            var projects = all_projects.data
            setMainProjects(projects)

        }
    }



    function LocationMarker() {
        const [position, setPosition] = useState(null)
        const map = useMapEvents({
            click() {
                map.locate()
            },
            locationfound(e) {
                // setPosition(e.latlng)
                // map.flyTo(e.latlng, 12)
            },
        })

        // return position === null ? null : (
        //     <Marker position={position}>
        //         <Popup>You are here</Popup>
        //     </Marker>
        // )
    }


    const [pointMarker, setPointsMarker] = useState([])
    const [linePoly, setLinePoly] = useState([])
    const [gonePoly, setGonePoly] = useState([])

    function getSubprojects(project) {
        setSubProjects(project.sub_project)
        setPointsMarker([])
        setLinePoly([])
        setGonePoly([])
        const subProjects = project.sub_project
        subProjects.forEach(subproject => {
            var geom = subproject.infrastructure.geom
            const cleanedWKT = geom.split(';')[1];
            const geom_data = WKT.parse(cleanedWKT);
            if (geom_data.type.includes("Point")) {
                var position = geom_data.coordinates.reverse()
                var value = <Marker position={position}>
                    <Popup>
                        <div className="">
                            <div className="title xs font-medium text-slate-400">
                                {subproject.title.toUpperCase()}
                            </div>
                            <div className="title flex items-center xs font-normal my-2">
                                <BsBookmarkFill className="mr-2" style={{ color: constant.getStateWithColor(subproject.status).color }} />
                                {subproject.status}
                            </div>
                            <div className="title flex items-center xs font-normal my-2">
                                <BsCalendar3 className="mr-2" />
                                <div className="xs">
                                    {`Du ${subproject.end_date} Au ${subproject.end_date} `}
                                </div>
                            </div>
                            <div className="title xs my-2 text-slate-400">
                                {subproject.description}
                            </div>
                        </div>
                    </Popup>
                </Marker>
                setPointsMarker(pointMarker => [...pointMarker, value]);
            } else if (geom_data.type.includes("LineString")) {
                var line = geom_data.coordinates.map(subArray => subArray.reverse());
                var value = <Polyline pathOptions={{ color: project.category.color }} positions={line} />
                setLinePoly(linePoly => [...linePoly, value])
            } else {
                var polygone = geom_data.coordinates.map(subArray => subArray.map((i) => i.reverse()));
                var value = <Polygon pathOptions={{ color: project.category.color }} positions={polygone} />
                setGonePoly(gonePoly => [...gonePoly, value])
            }

        });
    }


    const onEachFeature = (feature, layer) => {
        layer.on({
            mouseover: (e) => handleMouseOver(e, layer),
            mouseout: (e) => handleMouseOut(e, layer)
        });
    };
    const setColor = ({ properties }) => {
        return { weight: 1, color: '#11A8DA', opacity: 0.1, };
    };
    const handleMouseOver = (e, layer) => {
        layer.setStyle({
            color: 'black', weight: 2, opacity: 0.1,
        });
        layer.bringToFront();
    };

    const handleMouseOut = (e, layer) => {
        layer.setStyle({
            color: '#11A8DA',
            opacity: 0.1,
        });
    };



    function onSubproject(subproject, project) {
        setPointsMarker([])
        setLinePoly([])
        setGonePoly([])
        var geom = subproject.infrastructure.geom
        const cleanedWKT = geom.split(';')[1];
        const geom_data = WKT.parse(cleanedWKT);
        if (geom_data.type.includes("Point")) {
            var position = geom_data.coordinates.reverse()
            var value = <Marker position={position}>
                <Popup>
                    <div className="">
                        <div className="title xs font-medium text-slate-400">
                            {subproject.title.toUpperCase()}
                        </div>
                        <div className="title flex items-center xs font-normal my-2">
                            <BsBookmarkFill className="mr-2" style={{ color: constant.getStateWithColor(subproject.status).color }} />
                            {subproject.status}
                        </div>
                        <div className="title flex items-center xs font-normal my-2">
                            <BsCalendar3 className="mr-2" />
                            <div className="xs">
                                {`Du ${subproject.end_date} Au ${subproject.end_date} `}
                            </div>
                        </div>
                        <div className="title xs my-2 text-slate-400">
                            {subproject.description}
                        </div>
                    </div>
                </Popup>
            </Marker>
            setPointsMarker(pointMarker => [...pointMarker, value]);
        } else if (geom_data.type.includes("LineString")) {
            var line = geom_data.coordinates.map(subArray => subArray.reverse());
            var value = <Polyline pathOptions={{ color: project.category.color }} positions={line} />
            setLinePoly(linePoly => [...linePoly, value])
        } else {
            var polygone = geom_data.coordinates.map(subArray => subArray.map((i) => i.reverse()));
            var value = <Polygon pathOptions={{ color: project.category.color }} positions={polygone} />
            setGonePoly(gonePoly => [...gonePoly, value])
        }

    }

    function Project({ project, index }) {
        const [ishow, setShowSub] = useState(false)
        return <div key={index}>
            {<div className={`project-item duration-1000 justify-between  items- event-item-${project.id} `} id={project.id} >
                <div className="flex  items-start">
                    <div className="project-item-icon mx-1" style={{ background: `${project.category.color}` }}></div>
                    <div className={`event-item-${project.id}`}>
                        <div onClick={() => setShowSub(!ishow)} className=" hover:bg-slate-200 duration-700 px-1 rounded-sm cursor-pointer">
                            <h3 className={`item-title-project event-item-${project.id}`}>{project.category.name.toUpperCase()}</h3>
                            <p className={`item-sub-title-project event-item-${project.id}`}>{project.title.toLowerCase()}</p>
                        </div>
                        {
                            project.sub_project.map((subproject, index) => {
                                return <>
                                    {ishow == true ? <div onClick={() => onSubproject(subproject, project)} className="flex items-center bg-slate-300 cursor-pointer  my-2 px-1 py-2 hover:bg-slate-400 duration-700 rounded-sm" key={index} >
                                        <BsFillCaretRightFill />
                                        <p className={` text-gray-800 mx-1 font-normal`}>{subproject.title}</p>
                                    </div> : null}
                                </>
                            })
                        }
                    </div>
                </div>
                <BsArrowRightCircleFill className="mx-1 duration-1000 hover:text-xl" onClick={() => getSubprojects(project)} />
            </div>}
        </ div>
    }
  

    const [projetIsshow, setIshowProjet] = useState(true)
    return <div className="map-container relative h-full bg-slate-400" >
        <MapContainer id="map" center={[-4.366609, 21.911680]} zoom={5.5} scrollWheelZoom={true}>
            {projetIsshow == true ? <div className="map-project p-2  bg-white h-10 w-10 rounded-sm duration-1000 ">
                <div className="map-project-title flex justify-between items-center">
                    <label className=" text-xs font-medium">PROJECTS MIN. DEV. RURAL</label>
                    <div className="w-6 h-6 bg-red-600 cursor-pointer rounded-sm grid place-content-center text-white " onClick={() => setIshowProjet(false)}><FaTimes /></div>
                </div>
                <div className="project-container py-3 overflow-auto " style={{ height: '100vh' }}>

                    {
                        mainProjects.length ? mainProjects.map((project, index) => (
                            <Project index={project.id} project={project} />
                        )) : <div className="flex justify-center items-center font-semibold">ATTENTE ...</div>

                    }
                </div>
            </div> : <div className=" duration-1000 open-project border cursor-pointer" onClick={() => setIshowProjet(true)}>
                <BsBorderWidth />
            </div>}

            <TileLayer
                attribution="VITRINE DEV. RURAL"
                url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
                // url="https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png"
                minZoom={0}
                maxZoom={20}
                ext='png'
            />
            {provinces != null ? <GeoJSON data={provinces} style={setColor} onEachFeature={onEachFeature} /> : null}
            {pointMarker.length ? pointMarker.map((MARKER, index) => {
                return MARKER
            }) : null}
            {linePoly.length ? linePoly.map((POLYLINE, index) => {
                return POLYLINE
            }) : null}
            {gonePoly.length ? gonePoly.map((POLYGONE, index) => {
                return POLYGONE
            }) : null}
            <LocationMarker />
        </MapContainer>






        {/* <div id="map leafletmap" className="map bg-slate-300">
            <div className="map-project p-2 bg-white h-10 w-10 rounded-sm">
                <div className="map-project-title flex justify-between items-center">
                    <label className=" text-xs font-medium">PROJECTS MIN. DEV. RURAL</label>
                    <div className="w-6 h-6 bg-red-600 cursor-pointer rounded-sm grid place-content-center text-white "><FaTimes /></div>
                </div>
                <div className="project-container py-3">
           
                </div>
            </div> 
        </div> */}
        {/* <div className="map-legend my-3 flex ">
            <div className="legend-item flex legend-field-event  items-cente cursor-pointer duration-700 border hover:bg-slate-200 p-3">
                <BsBookmarkFill className=" text-green-600" />
                <div className="title mx-2 text-xs font-medium">CHAMPS ET PLANTATIONS</div>
            </div>
            <div className="legend-item legend-aero-event roevent flex  items-center ml-5 cursor-pointer duration-700 border hover:bg-slate-200 p-3 ">
                <BsBookmarkFill className=" text-red-600" />
                <div className="title mx-2 text-xs">AEROPORT</div>
            </div>
            <div className="legend-item legend-route-event flex  items-center ml-5 cursor-pointer duration-700 border hover:bg-slate-200 p-3 ">
                <BsBookmarkFill className=" text-yellow-500" />
                <div className="title mx-2 text-xs">ROUTES</div>
            </div>
            
        </div> */}
    </div>
}