import React from "react"
import { BsPlug,BsColumnsGap,BsChatLeftText } from "react-icons/bs";


export default function HomeObjt(){
    return <div className="home-obj flex justify-center items-center m-2 p-3 flex-wrap">
           <div className="item w-full md:w-48 flex m-1 hover:bg-red-100 duration-1000 cursor-pointer  px-2 py-2 items-center border rounded-md shadow-xs shadow-black">
                <BsPlug className="text-xl text-red-600 cursor-pointer  "/>
                <label className="text-sm px-2 cursor-pointer z z">Principaux projets</label>
           </div>
           <div className="item  w-full md:w-48  flex m-1  px-2 py-2 hover:bg-yellow-100 duration-1000 cursor-pointer  items-center border rounded-md shadow-xs shadow-black">
                <BsColumnsGap className="text-xl text-yellow-600 cursor-pointer  "/>
                <label className="text-sm px-2 cursor-pointer  ">Les projets encours</label>
           </div>
           <div className="item  w-full md:w-48  flex m-1  px-2 py-2 hover:bg-green-100 duration-1000 cursor-pointer  items-center border rounded-md shadow-xs shadow-black">
                <BsColumnsGap className="text-xl text-green-600 cursor-pointer"/>
                <label className="text-sm px-2 cursor-pointer ">Les projets réalisés</label>
           </div>
           <div className="item  w-full md:w-48  flex m-1 bg-purple-50 hover:bg-purple-100 duration-1000 cursor-pointer px-2 py-2 items-center border rounded-md shadow-xs shadow-black">
                <BsChatLeftText className="text-xl text-purple-600 cursor-pointer  "/>
                <label className="text-sm px-2 cursor-pointer  ">Commentaires</label>
           </div>
    </div>
}