import React, { useEffect, useState } from "react"
import { FaAlignJustify, FaTimes } from 'react-icons/fa';
import { useNavigate } from "react-router-dom";
import logowhite from '../../src/assets/logo-white.png'
import logocolor from '../../src/assets/logo-color.png'
import BGVideo from '../../src/assets/video.mp4'
import colors from "../contants/constant";

export default function HomeHeader() {
    return <div className= "homer-header bg-gray-400 relative mt-20 md:mt-0 ">
            <video
                className="absolute top-0 left-0 w-full h-full object-cover  "
                src={BGVideo}
                loop={true} autoPlay={true} muted
            />
            <MenuBar/>
            <div className="absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center bg-black bg-opacity-50">
                <div className={`text-white my-2  tracking-wide font-bold text-center text-2xl md:text-3xl`}>VITRINE DE DEVELOPPEMENT RURAL </div>
                <div className=" text-gray-200 my-1  tracking-wide text-sm mx-4 md:mx-1 text-center md:xl ">Explorez nos sections pour en savoir plus sur nos programmes,<br/> nos réalisations et comment nous travaillons pour améliorer la qualité de vie dans les zones rurales<br/>  sur l'entendue de la RDC.</div>
                <div  className={`${colors.mainBG}  my-6 text-white  text-xs font-semibold px-10 py-4 duration-700 hover:bg-green-700 cursor-pointer`}>COMMENCER</div>
            </div>
            oiu
    </div>
}





// MENU APPLICATION
const MenuBar = () => {
    var goto = useNavigate()
    const [menumenuIsHow, setIsShow] = useState(false);
    useEffect(() => {
        const handleResize = () => setIsShow(window.innerWidth >= 768);
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    const [manuBg,setManuBg] = useState("rgba(10, 9, 9, 0.438)");
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const [logo,setlogo] = useState({
        image:logowhite,
        size:25,
        bg:"transparent"
    });
    const handleScroll = () => {
        const position = window.pageYOffset;
        if(position>50){
            setManuBg("rgb(8, 110, 8)")
            setlogo({
                image:logocolor,
                size:38,
                bg:"#213126"
            })
        }else{
            setManuBg("rgba(10, 9, 9, 0.438)")
            setlogo({
                image:logowhite,
                size:25,
                bg:"transparent"
            })
        }
    };

    return <div style={{ backgroundColor: logo.bg, transition: '1s' }} className={`menu fixed top-0 w-full z-10 flex flex-col md:flex-row justify-between px-5 py-2   md:bg-transparent`}>
        <div className="flex justify-between items-center">
            <div className="logo my-3 md:my-0">
            <img className=" h-14"  src={logo.image}  />
            </div>
            <div className="logo my-3 flex md:hidden" onClickCapture={() => setIsShow(!menumenuIsHow)}>
                {menumenuIsHow == true ? <FaTimes  className=" text-white"/> : <FaAlignJustify className=" text-white" />}
            </div>

        </div>
        {menumenuIsHow == true ? <div className="menu-items flex flex-col text-sm md:flex-row text-white ">
            <div className="item cursor-pointer px-3 py-auto h-14 grid place-content-center hover:bg-gray-300 hover:font-medium duration-700 hover:text-black" onClick={() => goto('/')}>ACCUEIL</div>
            <div className="item cursor-pointer px-3 py-auto h-14 grid place-content-center hover:bg-gray-300 hover:font-medium duration-700 hover:text-black" onClick={() => goto('/planification')}>PLANIFICATIONS</div>
            <div className="item cursor-pointer px-3 py-auto h-14 grid place-content-center hover:bg-gray-300 hover:font-medium duration-700 hover:text-black" onClick={() => goto('/projects')}>PROJETS</div>
            <div className="item cursor-pointer px-3 py-auto h-14 grid place-content-center hover:bg-gray-300 hover:font-medium duration-700 hover:text-black" onClick={() => goto('/cartography')}>CARTOGRAPHIE</div>
        </div> : null}
    </div>
}