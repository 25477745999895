

const mainBG="bg-emerald-950";
const mainText="text-emerald-950";
const entryReact="http://185.215.165.213:8500/api/";

function getStateWithColor(state) {
    const stateColors = {
        "En attente": "#808080",    // Gris
        "En planification": "#1E90FF", // Bleu
        "En cours": "#32CD32",     // Vert
        "En révision": "#FFA500",  // Orange
        "Suspendu": "#FF0000",     // Rouge
        "En retard": "#FF6347",    // Rouge Tomate
        "Terminé": "#008000"       // Vert Foncé
    };

    const color = stateColors[state];
    if (color) {
        return { state, color };
    } else {
        return { state: "Inconnu", color: "#000000" }; // Noir pour les états inconnus
    }
}


export default {mainBG, mainText,entryReact,getStateWithColor};