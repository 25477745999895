import React from "react"
import HomeHeader from "../components/home.header"
import HomeObjt from "../components/home.objectifs"
import MapContainerLocal from "../components/home.map"


export default function HomePage(){
    return <div className="home-page">
           <HomeHeader/>
           <HomeObjt/>
           <MapContainerLocal/>
    </div>
}