import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import HomePage from "../src/pages/home";
import reportWebVitals from './reportWebVitals';
import './../src/index.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";



const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
  <React.StrictMode>
    <div className=''>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/planification" element={<HomePage />} />
          <Route path="/projects" element={<HomePage />} />
          <Route path="/cartography" element={<HomePage />} />
        </Routes>
      </BrowserRouter>
    </div>

  </React.StrictMode>
);


reportWebVitals();
